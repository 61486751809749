import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import './LegalPage.scss';
import Header from './Header';
import { Loader } from './Loader';
import {
  sanity_all_categories,
  sanity_articleByCategoryPathname,
  sanity_articleById,
  sanity_articleByPathname
} from './sanityApi';
import { ApiError } from './ApiError';

const LegalPage = () => {
  const { search } = useLocation();
  const { title } = queryString.parse(search);
  const [articleTitle, setArticleTitle] = useState(title || '');
  const [articleBg, setArticleBg] = useState('');
  const { data, isFetching, error } = useQuery({ queryKey: ['sanity_all_categories'], queryFn: sanity_all_categories });

  const backgroundStyle = {
    backgroundImage: `url(${articleBg || '/public_assets/bg.png'})`
  };

  if (error) {
    return <ApiError />;
  }

  return (
    <div className="legal-page">
      <Header title={title || articleTitle} style={backgroundStyle} />
      <div className="content">
        {isFetching ? (
          <Loader />
        ) : (
          <Article categoryPathnames={data} setArticleTitle={setArticleTitle} setArticleBg={setArticleBg} />
        )}
      </div>
    </div>
  );
};

const Article = ({ categoryPathnames, setArticleTitle, setArticleBg }) => {
  const { pathname, search } = useLocation();
  const { current } = queryString.parse(search);
  const [currentArticle, setCurrentArticle] = useState(null);
  const history = useHistory();

  const { isFetching, error, data } = useQuery({
    queryKey: ['sanity_articles', current, pathname],
    queryFn: () => {
      if (current) return sanity_articleById(current);
      if (categoryPathnames.includes(pathname)) return sanity_articleByCategoryPathname(pathname);
      return sanity_articleByPathname(pathname);
    }
  });

  useEffect(() => {
    if (data) setCurrentArticle(data.shift());
  }, [data]);

  useEffect(() => {
    if (!currentArticle) return;
    setArticleTitle(currentArticle.category.title);
    setArticleBg(currentArticle.category.bg);
  }, [currentArticle]);

  const onChangeSelect = (e) => {
    history.push(`${pathname}?current=${e.target.value}`);
  };

  if (error) {
    return <ApiError />;
  }
  if (isFetching || !currentArticle) {
    return <Loader />;
  }

  let h1Order = 1;
  return (
    <>
      <SelectList versions={currentArticle.versions} selected={currentArticle} onChange={onChangeSelect} />
      <Contents body={currentArticle.body} />
      <div
        className="document"
        dangerouslySetInnerHTML={{
          __html: currentArticle.body.replace(/<h1>(.*?)<\/h1>/g, (match) => {
            return `<a name="${h1Order++}"></a>${match}`;
          })
        }}
      />
    </>
  );
};

const SelectList = ({ versions, selected, onChange }) => {
  if (!selected.embargoDate) return null;
  return (
    <div className="toc">
      <div className="selector">
        <select className="list" value={selected?._id} onChange={onChange}>
          {versions.map((article) => (
            <option key={article._id} value={article._id}>
              {article.embargoDate?.substring(0, 10)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const Contents = ({ body }) => {
  const tocEntries = buildToc(body);
  return (
    <div className="toc-entries">
      {tocEntries.length > 0 && <div className="toc-title">목차</div>}
      {tocEntries.map((entry, index) => (
        <div key={index} className="entry">
          <a href={`#${index + 1}`}> {entry} </a>
        </div>
      ))}
    </div>
  );
};

const buildToc = (body) => {
  const h1Entries = body.match(/(?:<h1>[^<]+<\/h1>)/gm);
  return h1Entries && h1Entries.length ? h1Entries.map((html) => html.substring(4, html.length - 5)) : [];
};

export default LegalPage;
